import React from 'react'
import { Navigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const Public = ({ children }) => {
    const token = localStorage.getItem('rhh_token');
    if (token) {
      return <Navigate to="/" replace />
    }
    return children
}
export default Public